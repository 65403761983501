export const environment =
{
   production: false,
   apiCustomRootUrl: 'https://www.buchheits.com/backend/',
   apiCustomIpAddress: 'https://geolocation-db.com/json/',
   apiRebate:  'https://og.buchheits.com/Online/api/',
   ClientGuid: 'bbe730269974430ea5c8caa4eadb9d91',
   trackingUrl: 'https://tracking-na.hawksearch.com',
   recommendationsUrl: 'https://recs-na.hawksearch.com',
   s3Acess: {
       accessKeyId: '',
       secretAccessKey: '',
       region: '',
       signatureVersion: 'v4'
    },
    imageBucket: 'buchheitv2',
    videoBucket: 'buchheitv2',
    teamMemberFolder: 'party/',
    businessUnitFolder: 'party/',
    storeFolder: 'party/',
    taxonomyBanner: 'taxonomy/',
    taxonomyThumbnail: 'taxonomy/',
    taxonomyIcon: 'taxonomy/',
    productFolder: 'items/',
    reviewFolder: 'review/',
    docUrl: 'https://images.buchheits.com/',
    videoUrl: 'https://d3sfvumtar0nke.cloudfront.net/',
    firebaseAccess: {
        apiKey: 'AIzaSyDzuvBpCxAD_2j6N9Grcn5J2-PbzWwR01Q',
        authDomain: 'buchheit-pim-prod.firebaseapp.com',
        projectId: 'buchheit-pim-prod',
        storageBucket: 'buchheit-pim-prod.appspot.com',
        messagingSenderId: '723622785181',
        appId: '1:723622785181:web:5655e50db765e5850ea474',
        measurementId: 'G-8E6K391WDE',
    },
    flipAccess:{
        accessToken: '9f196d4404f7f87a040fe7a1cc21bf88',
        merchantId: '5223',
        merchantNameIdentifier: 'buchheit'
    },
    isDevServer: false,
    stipePublicKey: 'pk_live_maUBHoJpe1bCoLK2HRyCzTzU',
    AWS_S3_XML_FOLDER: 'assets/xml'
  };