import { Component, OnInit, DoCheck, AfterViewInit, ElementRef, Renderer2, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { UtilityService } from '../../core/services/utility.service';
import { combineLatest, Subject, Observable } from 'rxjs';
import { switchMap, debounceTime, take } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { HawkSearchConfigService } from '@app/core/services/hawk-search-config-service.service';
import { CurrencyPipe } from '@angular/common';
import { HawksearchEventTrackingService } from '@app/core/services/hawksearch-event-tracking.service';
declare let window: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
  SearchBar = false;
  value = "";
  searchData: any = [];
  inTaxonomy: any = [];
  isLoading: boolean = false;
  searchBarActive: boolean = false;
  storeId: any;

  searchString: Subject<string> = new Subject();
  searchString$: Observable<string> = this.searchString.asObservable();
  autocompleteResponseNew: any;
  data = {
    8: {
      price: "8_house_springs_price",
      inventory: "8_house_springs_inventory"
    },
    7: {
      price: "7_jacksonville_price",
      inventory: "7_jacksonville_inventory"
    },
    6: {
      price: "6_herculaneum_price",
      inventory: "6_herculaneum_inventory"
    },
    5: {
      price: "5_greenville_price",
      inventory: "5_greenville_inventory"
    },
    4: {
      price: "4_jackson_price",
      inventory: "4_jackson_inventory"
    },
    3: {
      price: "3_centralia_price",
      inventory: "3_centralia_inventory"
    },
    32: {
      price: "32_north_platte_price",
      inventory: "32_north_platte_inventory"
    },
    31: {
      price: "31_beatrice_price",
      inventory: "31_beatrice_inventory"
    },
    30: {
      price: "30_murray_price",
      inventory: "30_murray_inventory"
    },
    2: {
      price: "2_perryville_price",
      inventory: "2_perryville_inventory"
    },
    29: {
      price: "29_pocahontas_price",
      inventory: "29_pocahontas_inventory"
    },
    27: {
      price: "27_paragould_price",
      inventory: "27_paragould_inventory"
    },
    26: {
      price: "26_jonesboro_price",
      inventory: "26_jonesboro_inventory"
    },
    25: {
      price: "25_columbia_north_price",
      inventory: "25_columbia_north_inventory"
    },
    24: {
      price: "24_columbia_south_price",
      inventory: "24_columbia_south_inventory"
    },
    23: {
      price: "23_fulton_price",
      inventory: "23_fulton_inventory"
    },
    22: {
      price: "22_kirksville_price",
      inventory: "22_kirksville_inventory"
    },
    21: {
      price: "21_blue_springs_price",
      inventory: "21_blue_springs_inventory"
    },
    20: {
      price: "20_jefferson_city_price",
      inventory: "20_jefferson_city_inventory"
    },
    1: {
      price: "1_sparta_price",
      inventory: "1_sparta_inventory"
    }
  };
  private clickListener: () => void;


  constructor(public commonService: CommonService, public router: Router, private activatedRoute: ActivatedRoute, public utilityService: UtilityService, public analyticsService: GoogleAnalyticsService, private hawkSearchService: HawkSearchConfigService, private el: ElementRef, private renderer: Renderer2, private currencyPipe: CurrencyPipe, private ngZone: NgZone, private hawkEventService: HawksearchEventTrackingService) { }
  ngOnDestroy(): void {
    document.removeEventListener('click', this.clickListener);
  }
  ngAfterViewInit(): void {
    if (this.hawkSearchService.hawkSearch) {
      let promotionTimeout: any;
      this.renderer.listen('window', 'hawksearch:autocomplete-completed', (event: any) => {
        const autocompleteResponse = event.detail;

        if (!autocompleteResponse) return;

        const { query, products, categories, viewAllText } = autocompleteResponse;

        // Trigger analytics service
        this.analyticsService.search(query);

        if (!products?.results?.length) return;

        this.processSearchResponseForImage(autocompleteResponse);

        this.bindGlobalFunctions(autocompleteResponse);
        this.registerHandlebarsHelpers();

        this.extractLastLevelTitle(categories?.results);
        autocompleteResponse.viewAllText = this.cleanViewAllText(viewAllText);

        this.initializeSearchBoxEvents();
        this.autocompleteResponseNew = autocompleteResponse;
      });

    }
  }

  extractLastLevelTitle(categories) {
    return categories.map(category => {
      // Split the title by &raquo; and trim to get the last level
      const parts = category.title.split('&raquo;').map(part => part.trim());
      // Update the title with the last element
      category.title = parts[parts.length - 1];
      return category;
    });
  }

  cleanViewAllText(viewAllText) {
    // Remove the word "please" and trim both sides of the string
    return viewAllText.replace(/please/gi, '').trim();
  }

  initializeSearchBoxEvents() {
    let self = this;
    const clearButton = document.querySelector('hawksearch-search-field')?.shadowRoot?.getElementById('clear-button');
    const autocompleteElement: any = document.querySelector('hawksearch-search-field')?.shadowRoot?.querySelector('hawksearch-autocomplete');
    const searchInput: any = document.querySelector('hawksearch-search-field')?.shadowRoot?.getElementById('search-input');
    if (!autocompleteElement) return;

    this.renderer.listen(searchInput, 'input', () => {
      if (searchInput.value) {
        this.value = searchInput.value;
        this.renderer.setStyle(autocompleteElement, 'display', 'block'); // Show the autocomplete element
        this.renderer.setStyle(clearButton, 'display', 'inline'); // Show the clear button
      } else {
        this.renderer.setStyle(autocompleteElement, 'display', 'none'); // Hide the autocomplete element
        this.renderer.setStyle(clearButton, 'display', 'none'); // Hide the clear button
      }
    });


    // Optionally, you can show the autocomplete element again when the input is focused
    this.renderer.listen(searchInput, 'focus', () => {
      if (searchInput.value) {
        this.renderer.setStyle(autocompleteElement, 'display', 'block'); // Show the autocomplete element
      }
    });



    // Bind a click event to the search input
    this.renderer.listen(searchInput, 'keyup', (event: KeyboardEvent) => {
      event.preventDefault();
      if (this.isMobile()) { // Assuming `isMobile` is a method in your component
        if (event.key === 'Enter') {
          const query = (event.target as HTMLInputElement).value;
          const url = `search?query=${encodeURIComponent(query)}`;
          window.location.href = url;
        }
      } else {
        console.log('Not a mobile device');
      }
    });

  }
  isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 800;
  }

  processSearchResponseForImage(searchResponse) {
    searchResponse.products.results.forEach(result => {
      if (result.attributes.image && !result.attributes.image[0]?.includes('https://images.buchheits.com/')) {
        result.attributes.image[0] = `https://images.buchheits.com/items//fit-in/200x200/${result.attributes.image[0] || 'default-item-image.png'}`;
        result.imageUrl = result.attributes.image[0];
      }
    });
  }

  processSearchResponseForPriceAndUrl(searchResponse) {
    const storeId = localStorage.getItem('storeId');
    const storeKey = this.data[storeId].price;
    searchResponse.products.results.forEach(result => {
      result.price = result.attributes[storeKey][0];
    });
  }

  /*updateProductLinks(data) {
    const elements = document.querySelector('hawksearch-search-field')
      ?.shadowRoot?.querySelector('hawksearch-autocomplete')
      ?.shadowRoot?.querySelectorAll('.autocomplete__products .column-sm--4 a[hawksearch-product]');

    if (elements?.length) {
      const productMap = new Map(data.map(item => [item.id, item.attributes?.item_slug?.[0]]));

      elements.forEach((element: any) => {
        const productId = element.getAttribute('hawksearch-product');
        const itemSlug = productMap.get(productId);
        if (itemSlug) {
          element.href = `p/${itemSlug}`;
        }
      });
    } else {
    }
  }*/




  ngOnInit(): void {

    this.storeId = JSON.parse(localStorage.getItem('storeId'));

    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .subscribe(([params, query]) => {
        if (Object.keys(query).length > 0) {
          if (query.search !== undefined) {
            this.value = query.search;
          }
        }
        else {
          this.value = '';
        }
      });

    this.searchString$.pipe(
      debounceTime(500),
      //distinctUntilChanged(),
      switchMap((searchValue: any) =>
        this.commonService.getAllData({ tableId: 'products', params: { 'store': localStorage.getItem('storeId'), 'limit': this.searchLimit, offset: this.searchOffset, 'search': searchValue.replace(/[`~!@$%^#&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '_') } })
      )
    )
      .subscribe((data: any) => {
        if (!this.storeId) {
          this.storeId = localStorage.getItem('storeId');
        }
        this.isLoading = false;
        if (this.isenterPress.type == false) {
          this.searchData = data.data;
          this.totalData = data.total;
          this.inTaxonomy = [];
          this.searchData.forEach((item: any) => {

            let url = '';
            if (item && item !== 'undefined' && item.hasOwnProperty('Item') && item.Item.hasOwnProperty('item_slug') && item.Item.item_slug && item.Item.item_slug !== 'undefined') {
              url = `/p/${item.Item.item_slug}`;
            }
            else if (item && item !== 'undefined' && item.hasOwnProperty('Item') && item.Item.hasOwnProperty('id')) {
              url = `/p/${item.Item.id}`;
            }
            item.url = url;
            if (item.hasOwnProperty('itemmerchandisehierarchygroup') && item.itemmerchandisehierarchygroup !== 'undefined' && item.itemmerchandisehierarchygroup.length > 0) {
              const taxonomy = this.getTaxonomyName(item.itemmerchandisehierarchygroup);
              if (this.inTaxonomy.find((element: any) => element.merchandisehierarchygroupname === taxonomy.merchandisehierarchygroupname) === undefined) {
                this.inTaxonomy.push({ merchandisehierarchygroupname: taxonomy.merchandisehierarchygroupname, url: `listing/${taxonomy.merchandisehierarchygroupslug}` });
              }
            }
          });
        }
      });
    this.searchString$.pipe(debounceTime(500)).subscribe((val: any) => {
      if (val) this.analyticsService.search(val);
    })

    this.clickListener = this.onClickOutside.bind(this);
    this.clickListener && document.addEventListener('click', this.clickListener);

  }

  // Handle click events
  private onClickOutside(event: MouseEvent): void {
    const searchBox = this.el.nativeElement.querySelector('.searchBar');

    if (!searchBox) {
      return;
    }

    const rect = searchBox.getBoundingClientRect();

    if (event.clientY > rect.height && searchBox.classList.contains('active') && !searchBox.contains(event.target as Node)) {
      document.querySelectorAll('.searchBar.active').forEach(element => {
        element.classList.remove('active');
      });
      this.searchString.next('');
    }
  }


  searchBar() {
    const searchBarContainer = Array.from(document.getElementsByClassName('searchBar') as HTMLCollectionOf<HTMLElement>);
    searchBarContainer.forEach(element => { element.classList.remove("active") });
    this.searchString.next('');
  }

  searchLimit = 20;
  searchOffset = 0;
  totalData = 0;
  isenterPress: any = { 'searchText': '', 'type': false };
  globalSearch(event: any) {
    /*this.inTaxonomy = [];
    this.searchData = [];*/
    //this.searchBarActive = false;
    if (this.isenterPress.searchText !== this.value.trim()) {
      this.isenterPress.type = false;
    }
    this.isenterPress.searchText = this.value.trim();
    /**For enter key press */
    if (event.keyCode === 13) {
      this.isenterPress.type = true;
      let url = 'listing';
      this.searchData = [];
      this.totalData = 0;
      this.goToSearchListPage(url, 'ENTER');
    }
    else {
      this.isLoading = true;
      if (this.value.trim() !== '') {
        this.searchBarActive = true;
        this.searchString.next(this.value.trim());
      }
      else {
        this.value = '';
      }
    }
  }
  isElementsNewFound: boolean = false;

  ngDoCheck(): void {
    // Reset search data if the input value is empty
    if (this.value === '') {
      this.searchData = [];
      this.totalData = 0;
    }

    // Perform additional input value checks
    this.checkInputValue();

    // Cache DOM queries for better performance
    const searchFieldElement = document.querySelector('hawksearch-search-field');
    const shadowRoot = searchFieldElement?.shadowRoot;
    const clearButton = shadowRoot?.getElementById('clear-button');
    const searchInput = shadowRoot?.getElementById('search-input') as HTMLInputElement | null;

    const autocompleteElement = this.getAutocomplete();

    // Display the clear button if the search input has a value
    if (clearButton && searchInput && searchInput.value) {
      clearButton.style.display = 'inline';

      // Ensure the event listener is not added multiple times
      if (!clearButton.hasAttribute('listener-added')) {
        this.renderer.listen(clearButton, 'click', () => {
          this.renderer.setProperty(searchInput, 'value', ''); // Clear the input value
          this.renderer.setStyle(autocompleteElement, 'display', 'none'); // Hide the autocomplete element
          this.renderer.setStyle(clearButton, 'display', 'none'); // Hide the clear button
        });
      
        this.renderer.setAttribute(clearButton, 'listener-added', 'true'); // Add the custom attribute
      }
      
    }
  }

  getAutocomplete() {
    const autocompleteElement: any = document.querySelector('hawksearch-search-field')?.shadowRoot?.querySelector('hawksearch-autocomplete');
    return autocompleteElement;
  }

  bindGlobalFunctions(response: any): void {
    (window as any).handleItemClick = (url: string, id: any) => this.handleItemClick(url, id, response);
    (window as any).handleCategoryClick = (event: Event, encodedCategoryObject: string) => {
      this.handleCategoryClick(event, encodedCategoryObject);
    };

  }


  handleItemClick = (url: string, id: any, response: any) => {
    // Find the matched item in the response
    const matchedItem = response?.products?.results?.find(ele => ele.id === id);

    if (matchedItem) {
      // Trigger the analytics event for the clicked product
      this.analyticsService.productClick(matchedItem);
    }

    // Navigate to the provided URL and hide the autocomplete element
    this.ngZone.run(() => {
      this.router.navigate([url]);

      const autocompleteElement = this.getAutocomplete();
      if (autocompleteElement) {
        autocompleteElement.style.display = 'none';
      }
    });

    // Prepare and send the event object to Hawksearch Event Service
    const eventObj = {
      ItemId: id,
      Url: `https://${document.location.hostname}${url}`
    };
    this.hawkEventService.handleTrendingItemClick(eventObj);
  };



  handleCategoryClick = (event, encodedCategoryObject) => {
    // Prevent default behavior and stop event propagation
    event.preventDefault();
    event.stopPropagation();

    // Parse the encoded object
    const { field, title, url } = JSON.parse(encodedCategoryObject);

    // Construct the new object for the Hawksearch Event Service
    const new_obj = {
      Field: field,
      Value: title,
      Url: `https://${document.location.hostname}${url}`,
    };

    // Handle the trending category click event
    this.hawkEventService.handleTrendingCategoryClick(new_obj);

    // Navigate to the new URL and hide the autocomplete element
    this.ngZone.run(() => {
      this.router.navigateByUrl(url);

      // Hide the autocomplete element
      const autocompleteElement = this.getAutocomplete();
      if (autocompleteElement) {
        autocompleteElement.style.display = 'none';
      }
    });
  };



  registerHandlebarsHelpers(): void {
    const hawkSearch = this.hawkSearchService.hawkSearch;

    hawkSearch.handlebars.registerHelper('navigateToDetails', (item) => {
      if (
        item &&
        item !== 'undefined' &&
        item.attributes.item_slug[0]
      ) {
        return `/p/${item.attributes.item_slug[0]}`;
      } else {
        return '';
      }
    });

    hawkSearch.handlebars.registerHelper('jsonEncode', function (context) {
      return JSON.stringify(context);
    });
  }



  checkInputValue() {
    // Get the host element of the Shadow DOM
    const shadowHost = this.el.nativeElement.querySelector('hawksearch-search-field');

    if (shadowHost?.shadowRoot) {
      // Get the input element within the Shadow DOM
      const inputElement = shadowHost.shadowRoot.querySelector('input[hawksearch-input]') as HTMLInputElement;
      if (inputElement) {
        const value = inputElement.value;
        if (value === '') {
          this.autocompleteResponseNew = '';
        }
      }
    }
  }

  goToSearchPage(url: any) {
    this.searchBarActive = false;
    this.value = '';
    this.searchData = [];
    this.router.navigate([url]);
  }

  goToSearchListPage(url: any, calledFrom: string) {
    this.searchBarActive = false;
    this.searchData = [];
    if (calledFrom === 'ENTER') {
      this.value && this.router.navigate([url], { queryParams: { search: this.value } });
    } else {
      this.router.navigate([url]);
    }

  }

  getImageUrl(imageName: string, width: number, height: number, status: boolean, timestamp?: any): string {
    if (imageName !== undefined) {
      if (timestamp != null) return this.utilityService.getLazyImage(imageName, 'items', status, `${width}x${height}`, timestamp);
      else return this.utilityService.getLazyImage(imageName, 'items', status, `${width}x${height}`);
    } else {
      return 'assets/images/blank.png';
    }
  }

  setDefaultPic(i: any) {
    this.searchData[i].itemimages = [{ image: 'default-item-image.png' }];
  }

  getTaxonomyName(itemmerchandisehierarchygroup: any[]) {
    return itemmerchandisehierarchygroup.find(itemMerchandi => itemMerchandi.isprimary) || itemmerchandisehierarchygroup[0];
  }

  onBlur() {
    setTimeout(() => {
      this.searchBarActive = false;
    }, 100);
  }

}
